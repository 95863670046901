<template>
  <div class="login-container-en">
    <main class="login-main-en">
      <div class="login-card-en">
        <h2>Welcome</h2>
        <form @submit.prevent="login" class="card-en">
          <div class="form-group-en">
            <label for="email">Username or Email</label>
            <input type="text" id="email" v-model="email" />
            <p v-if="errors.email" class="error">{{ errors.email }}</p>
          </div>
          <div class="form-group-en">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" />
            <p v-if="errors.password" class="error">{{ errors.password }}</p>
          </div>
          <button type="submit" class="login-button-en" :disabled="isSubmitting">Login</button>
        </form>
        <div class="signup-link-en">
          <p>Don't have an account? <a href="/en/register">Create a new account</a></p>
        </div>
      </div>
      
      <div class="login-info-en">
        <h1 class="login-info-h1-en">We provide an <strong>innovative & unique</strong> learning experience</h1>
        <p class="login-info-p-en">Need help? You can contact us during</p>
        <button class="whatsapp-button-en">Chat with us <svg width="18px" height="18px" viewBox="0 0 32 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
                fill="#BFC8D0"></path>
              <path
                d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                fill="url(#paint0_linear_87_7264)"></path>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
                fill="white"></path>
              <defs>
                <linearGradient id="paint0_linear_87_7264" x1="26.5" y1="7" x2="4" y2="28"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#5BD066"></stop>
                  <stop offset="1" stop-color="#27B43E"></stop>
                </linearGradient>
              </defs>
            </g>
          </svg>
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref('');
    const password = ref('');
    const errors = ref({ email: '', password: '' });
    const errorMessage = ref('');
    const isSubmitting = ref(false);
    const validate = () => {
      errors.value.email = email.value.trim() === '' ? 'Email address is required' : '';
      errors.value.password = password.value.trim() === '' ? 'Password is required' : '';
      return !errors.value.email && !errors.value.password;
    };

    const login = async () => {
      if (!validate()) return;

      isSubmitting.value = true;
      errorMessage.value = '';

      try {
        const response = await axios.post('/api/login', {
          email: email.value,
          password: password.value
        });

        const userInfo = response.data.data;

        // Update the Vuex store
        store.dispatch('updateUser', userInfo);

        // Store the token and user name in sessionStorage
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));

        // Redirect to another page, e.g., home page
        router.push({ name: 'HomeAr' }); // Assuming you have a route named 'Home Page'
      } catch (error) {
        console.log(error);
        errorMessage.value = error.response ? error.response.data.data.error : error;
      } finally {
        isSubmitting.value = false;
      }
    };
    return {
      email,
      password,
      errors,
      errorMessage,
      isSubmitting,
      login
    };
  }
}
</script>

<style scoped>
/* Add your styles here */
.error {
  color: red;
}
</style>

<style scoped src="@/assets/styles/en/login.css"></style>