<template>
  <div class="shopping-cart">
    <main class="cart-main">
      <div class="cart-info">
        <h2>سلة المشتريات</h2>
      </div>
    </main>
    <div class="cart-page">
      <div v-if="cartItems.length === 0" class="empty-cart">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/cart.png" alt="Empty Cart Image" />
        <h2>لا يوجد لديك شىء في السلة</h2>
        <p>ابدأ رحلتك التعليمية الآن اشترك في دوراتنا واستفد من تجربة تعلم فريدة!</p>
        <button class="back-to-courses" @click="goToCourses">العودة للدورات</button>
      </div>
      <div v-else class="cart-content">
        <div class="cart-container cart-container-1">
          <div class="order-section">
            <table class="order-table">
              <thead>
                <tr>
                  <th>الدورة</th>
                  <th>الكمية</th>
                  <th>السعر</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="order-item"
                  v-for="(item, index) in cartItems"
                  :key="index"
                >
                  <td class="course">
                    {{
                      item.course_package.stage +
                      " - " +
                      item.course_package.name +
                      " - " +
                      item.course_package.period +
                      " Months"
                    }}
                  </td>
                  <td class="quantity">
                    <button @click="decreaseQuantity(item.id)">-</button>
                    <label class="p-4">{{ item.quantity }}</label>
                    <input type="hidden" v-model="item.quantity" readonly />
                    <button @click="increaseQuantity(item.id)">+</button>
                  </td>
                  <td class="price">
                    {{ item.single_price }} {{ cartSummary.currency_ar }}
                  </td>
                  <td>
                    <button class="remove-button" @click="removeItem(item.id)">
                      <svg
                        aria-hidden="true"
                        class="svg-inline--fa fa-xmark"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="xmark"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="order-table-mobile"
              v-for="(item, index) in cartItems"
              :key="index"
            >
              <tbody>
                <tr>
                  <td>الدورة</td>
                  <td class="course">
                    {{
                      item.course_package.stage +
                      " - " +
                      item.course_package.name +
                      " - " +
                      item.course_package.period +
                      " Months"
                    }}
                  </td>
                </tr>
                <tr class="order-item">
                  <td>الكمية</td>
                  <td class="quantity">
                    <button @click="decreaseQuantity(item.id)">-</button>
                    <label class="p-4">{{ item.quantity }}</label>
                    <input type="hidden" v-model="item.quantity" readonly />
                    <button @click="increaseQuantity(item.id)">+</button>
                  </td>
                </tr>
                <tr class="price-row">
                  <td>السعر</td>
                  <td class="price">
                    {{ item.single_price }} {{ cartSummary.currency_ar }}
                  </td>
                </tr>
                <tr class="remove-row">
                  <td></td>
                  <td colspan="2">
                    <button class="remove-button" @click="removeItem(item.id)">
                      <svg
                        aria-hidden="true"
                        class="svg-inline--fa fa-xmark"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="xmark"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="buy-another-course" @click="goToCourses">
              → شراء دورة اخرى
            </button>
            <div v-if="orderUpdated" class="order-update-message">
              تم تحديث سلة المشتريات.
            </div>
          </div>
        </div>
        <div class="cart-container cart-container-2">
          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <div class="coupon-section">
            <label for="coupon-code">لديك كود خصم ؟</label>
            <div class="coupon-input">
              <input
                type="text"
                id="coupon-code"
                placeholder="Coupon code"
                v-model="couponCode"
              />
              <button
                v-if="!cartSummary.coupon_code"
                @click="applyCoupon"
                class="apply-button"
              >
                تطبيق
              </button>
              <button
                v-if="cartSummary.coupon_code"
                @click="removeCoupon"
                class="remove-coupon-button"
              >
                إزالة الكوبون
              </button>
            </div>
          </div>
          <table class="price-table">
            <tbody>
              <tr>
                <td v-if="cartSummary.total_price_discount == 0">
                  السعر النهائي
                </td>
                <td v-else>السعر المبدائي</td>
                <td>
                  {{ cartSummary.total_price }} {{ cartSummary.currency_ar }}
                </td>
              </tr>
              <tr v-if="cartSummary.family_or_friend_discount > 0">
                <td>خصومات الأصدقاء/العائلة</td>
                <td>
                  <div class="remove-coupon-section">
                    - {{ cartSummary.family_or_friend_discount }}
                    {{ cartSummary.currency_ar }}
                  </div>
                </td>
              </tr>
              <tr v-if="cartSummary.coupon_code">
                <td>الخصم</td>
                <td>
                  <div class="remove-coupon-section">
                    - {{ cartSummary.discount }} {{ cartSummary.currency_ar }}
                  </div>
                </td>
              </tr>
              <tr v-if="cartSummary.total_price_discount != 0">
                <td>السعر النهائي</td>
                <td>
                  {{ cartSummary.total_price_discount }}
                  {{ cartSummary.currency_ar }}
                </td>
              </tr>
            </tbody>
          </table>
          <button class="confirm-button" @click="showAuthModal('stripe')">
            تأكيد الطلب
          </button>
          <div class="payment-type-container">
            <div class="price-container">
              <div class="price-section">
                <div class="tamara-payment">
                  <img src="https://monglish.b-cdn.net/home/tamara-logo.png" alt="tamara logo">
                  <div class="payment-content">
                    <p class="uppercase font-extrabold">التقسيط مع تمارا!</p>
                    <span class="uppercase">احجز مكانك وادفع مع تمارا!</span>
                  </div>
                  <div class="price-buy-button">
                    <button class="confirm-button" @click="showAuthModal('tamara')">ابدأ الآن</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="price-container">
              <div class="price-section">
                <div class="tabby-payment">
                  <img src="https://monglish.b-cdn.net/home/tabby-logo.png" alt="tabby logo">
                  <div class="payment-content">
                    <p class="uppercase font-extrabold">التقسيط مع تابي</p>
                    <span class="uppercase">ابدأ مع دفعات تابي السهلة!</span>
                  </div>
                  <div class="price-buy-button">
                    <button class="confirm-button" @click="showAuthModal('tabby')">ابدأ الآن</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Modal for Data-->
  <form v-show="showModal" class="modal" @submit.prevent="goToCheckout">
    <div class="modal-content">
      <!--Modal Header-->
      <div class="modal-header">
        <div class="header-left">
          <h2>
            إقرار قبول دراسي لأكاديمية مونجلش الدولية
            <br />
            "MIA-TC 01"
          </h2>
        </div>
        <div class="header-right">
          <p>التاريخ: <span id="currentDate"></span></p>
        </div>
        <!--Close Btn-->
        <span class="close" @click="closeModal">
          <svg
            width="34px"
            height="34px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
                fill="#fff"
              ></path>
            </g>
          </svg>
        </span>
      </div>

      <!--Modal Form-->
      <div class="checkout-div">
        <div class="checkout-formm">
          <div v-if="validationErrorMessage" class="validation-container">
            <p
              id="validationError"
              class="error"
              v-html="validationErrorMessage"
            ></p>
            <span class="error">,</span>
            <a
              v-if="link"
              href="https://front.monglish.academy/login"
              class="text-link"
              >Login</a
            >
            <span class="error">or enter another email</span>
          </div>

          <!-- First row of inputs -->
          <div class="input-row">
            <div class="form-group">
              <label for="firstname">الاسم الأول</label>
              <input type="text" id="firstname" v-model="firstname" />
              <p v-if="errors.firstname" class="error">
                {{ errors.firstname }}
              </p>
            </div>

            <div class="form-group">
              <label for="lastname">الاسم الأخير</label>
              <input type="text" id="lastname" v-model="lastname" />
              <p v-if="errors.lastname" class="error">{{ errors.lastname }}</p>
            </div>
          </div>

          <!-- Second row of inputs -->
          <div class="input-row">
            <div class="form-group">
              <label for="country">البلد / المنطقة</label>
              <select
                id="country"
                v-model="country"
                @change="updatePhoneNumber"
              >
                <option
                  v-for="country in sortedCountries"
                  :key="country.code"
                  :value="country.code"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="phone">رقم الهاتف (مع كود الدولة)</label>
              <input
                type="text"
                id="phone"
                v-model="phone"
                style="direction: ltr"
              />
              <p v-if="errors.phone" class="error">{{ errors.phone }}</p>
            </div>
          </div>

          <!-- Third row (full width input) -->
          <div class="form-group full-width">
            <label for="email">البريد الإلكتروني</label>
            <input type="email" id="email" v-model="email" />
            <p v-if="errors.email" class="error">{{ errors.email }}</p>
          </div>
        </div>
      </div>

      <!--Modal Header-->
      <div class="modal-terms">
        <h2>
          لضمان حقوقك الكاملة في التعاقد مع الأكاديمية، برجاء قراءة البنود
          التالية بعناية والموافقة عليها:
        </h2>
        <div class="terms-container">
          <div class="terms-content" ref="termsContent">
            <ol>
              <li>
                <strong>سياسات الأكاديمية:</strong><br />
                أتعهد بالالتزام بكافة السياسات والإجراءات المتبعة داخل
                الأكاديمية، وأقر بأنني قد اطلعت عليها وفهمتها بالكامل ووافقت
                عليها.
              </li>
              <li>
                <strong>الرسوم الدراسية:</strong><br />
                أقر بأنني قد اطلعت على الرسوم الدراسية المقررة وأتعهد بسدادها في
                المواعيد المحددة. أعلم أنه في حالة التأخر في السداد، يحق
                للأكاديمية اتخاذ الإجراءات المناسبة بما في ذلك تعليق الحساب أو
                وقف الخدمات التعليمية حتى سداد المستحقات.
              </li>
              <li>
                <strong>الحضور الإلكتروني والانضباط:</strong><br />
                أتعهد بضمان حضور ابني/ابنتي للحصص الدراسية الإلكترونية بانتظام
                وفي المواعيد المحددة، وأفهم أن التغيب المتكرر دون عذر مقبول قد
                يؤدي إلى إجراءات إدارية مثل إلغاء الحجز أو إعادة الجدولة
                بالإضافة إلى الالتزام بما ورد في نموذج
                <a href="/ar/terms-and-conditions/terms">الشروط والأحكام</a> بما
                يخص بند الحضور.
              </li>
              <li>
                <strong>السلوك الإلكتروني:</strong><br />
                أتعهد بمتابعة سلوك ابني/ابنتي خلال الحصص الدراسية الإلكترونية،
                وأدرك أن الأكاديمية لها الحق في اتخاذ إجراءات تأديبية في حال
                حدوث مخالفات سلوكية عبر المنصة الإلكترونية.
              </li>
              <li>
                <strong>التقنية والمسؤوليات:</strong><br />
                أقر بأنني مسؤول عن توفير جهاز إلكتروني واتصال إنترنت مستقر لضمان
                مشاركة ابني/ابنتي في الحصص الدراسية عبر الإنترنت، وأدرك أن
                الأكاديمية غير مسؤولة عن أي مشكلات تقنية تتعلق بالاتصال من
                جانبي.
              </li>
              <li>
                <strong>الانسحاب أو الإلغاء:</strong><br />
                أعلم أنه في حالة الرغبة في سحب ابني/ابنتي من الأكاديمية أو إلغاء
                الاشتراك في البرنامج، يجب إبلاغ الإدارة كتابةً قبل فترة زمنية
                محددة، وأوافق على الالتزام
                <a href="/ar/terms-and-conditions/refund">بسياسة الإلغاء</a>
                والاسترداد كما هو موضح في
                <a href="/ar/terms-and-conditions/terms">الشروط والأحكام</a>
                الخاصة بسياسات الأكاديمية.
              </li>
              <li>
                <strong>استخدام الوسائل التعليمية الإلكترونية:</strong><br />
                أوافق على استخدام الأكاديمية لمنصات التعليم الإلكتروني والتقنيات
                الحديثة لتقديم الدروس،وأقر بأنني قد قرأت وفهمت
                <a href="/ar/terms-and-conditions/privacy">بسياسة الخصوصية</a>
                المتعلقة باستخدام هذه الوسائل.
              </li>
              <li>
                <strong>الصور والمقاطع المصورة:</strong><br />
                <ul>
                  <li>
                    فتح الكاميرا طوال فترة المحاضرة لضمان جودة العملية التعليمية
                    وتسهيل التواصل بين المعلمين والمتدربين كافة.
                  </li>
                  <li>
                    يراعى التواجد في مكان هادئ بعيداً عن الضوضاء مع إحدى
                    الخلفيات المحددة من قِبل خدمة العملاء.
                  </li>
                  <li>استخدام شاشة كمبيوتر كحد أدنى أو لابتوب.</li>
                  <li>
                    الالتزام بالزي المناسب (أبيض – أزرق – أسود) وذلك حرصاً منا
                    على تنمية الانضباط لدى المتدرب.
                  </li>
                  <li>
                    جميع المحاضرات مسجلة ويتم إرسالها لجميع المتدربين للاستفادة
                    العامة. تعتبر الحصص المسجلة ملك خالص للأكاديمية ويحق لها
                    استخدامها على سبيل المثال "تحليل لمستويات الطلاب أو حملات
                    ترويجية للأكاديمية."
                  </li>
                </ul>
              </li>
              <li>
                <strong>الموافقة على الشروط:</strong><br />
                بمجرد الضغط على زر شراء، يعتبر هذا موافقة منك على الشروط
                والأحكام المذكورة أعلاه والمرفقة بنموذج رقم MIA-TC-01.
              </li>
            </ol>
          </div>
        </div>

        <label class="checkbox">
          <input type="checkbox" v-model="isChecked" />
          أوافق على جميع الشروط والأحكام
        </label>

        <button type="submit" class="checkout-button" :disabled="!isChecked">
          أوافق وأرغب بالاستمرار لإتمام الطلب
        </button>
      </div>
    </div>
  </form>
</template>

<script>
  import axios from "axios";
  import { countries } from "countries-list";
  export default {
    data() {
      return {
        preferredCountries: [
          'SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ', 'AD', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 
          'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 
          'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 
          'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EH', 'ER', 'ES', 'ET', 
          'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 
          'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IR', 
          'IS', 'IT', 'JE', 'JM', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 
          'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 
          'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 
          'NP', 'NR', 'NU', 'NZ', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'RE', 
          'RO', 'RS', 'RU', 'RW', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 
          'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 
          'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YT', 
          'ZA', 'ZM', 'ZW'
        ],
        countryCodes: {
          SA: "+966",KW: "+965",AE: "+971",QA: "+974",EG: "+20",JO: "+962",OM: "+968",YE: "+967",BH: "+973",IQ: "+964",AD: "+376",AF: "+93",AG: "+1-268",AI: "+1-264",AL: "+355",AM: "+374",AO: "+244",AQ: "+672",AR: "+54",
          AS: "+1-684",AT: "+43",AU: "+61",AW: "+297",AX: "+358-18",AZ: "+994",BA: "+387",BB: "+1-246",BD: "+880",BE: "+32",BF: "+226",BG: "+359",BI: "+257",BJ: "+229",BL: "+590",BM: "+1-441",BN: "+673",BO: "+591",BQ: "+599",
          BR: "+55",BS: "+1-242",BT: "+975",BV: "+47",BW: "+267",BY: "+375",BZ: "+501",CA: "+1",CC: "+61",CD: "+243",CF: "+236",CG: "+242",CH: "+41",CI: "+225",CK: "+682",CL: "+56",CM: "+237",CN: "+86",CO: "+57",CR: "+506",
          CU: "+53",CV: "+238",CW: "+599",CX: "+61",CY: "+357",CZ: "+420",DE: "+49",DJ: "+253",DK: "+45",DM: "+1-767",DO: "+1-809",DZ: "+213",EC: "+593",EE: "+372",EH: "+212",ER: "+291",ES: "+34",ET: "+251",FI: "+358",FJ: "+679",
          FK: "+500",FM: "+691",FO: "+298",FR: "+33",GA: "+241",GB: "+44",GD: "+1-473",GE: "+995",GF: "+594",GG: "+44-1481",GH: "+233",GI: "+350",GL: "+299",GM: "+220",GN: "+224",GP: "+590",GQ: "+240",GR: "+30",GS: "+500",GT: "+502",
          GU: "+1-671",GW: "+245",GY: "+592",HK: "+852",HM: "+672",HN: "+504",HR: "+385",HT: "+509",HU: "+36",ID: "+62",IE: "+353",IL: "+972",IM: "+44-1624",IN: "+91",IO: "+246",IR: "+98",IS: "+354",IT: "+39",JE: "+44-1534",JM: "+1-876",
          JP: "+81",KE: "+254",KG: "+996",KH: "+855",KI: "+686",KM: "+269",KN: "+1-869",KP: "+850",KR: "+82",KY: "+1-345",KZ: "+7",LA: "+856",LB: "+961",LC: "+1-758",LI: "+423",LK: "+94",LR: "+231",LS: "+266",LT: "+370",LU: "+352",
          LV: "+371",LY: "+218",MA: "+212",MC: "+377",MD: "+373",ME: "+382",MF: "+590",MG: "+261",MH: "+692",MK: "+389",ML: "+223",MM: "+95",MN: "+976",MO: "+853",MP: "+1-670",MQ: "+596",MR: "+222",MS: "+1-664",MT: "+356",MU: "+230",
          MV: "+960",MW: "+265",MX: "+52",MY: "+60",MZ: "+258",NA: "+264",NC: "+687",NE: "+227",NF: "+672",NG: "+234",NI: "+505",NL: "+31",NO: "+47",NP: "+977",NR: "+674",NU: "+683",NZ: "+64",PA: "+507",PE: "+51",PF: "+689",
          PG: "+675",PH: "+63",PK: "+92",PL: "+48",PM: "+508",PN: "+64",PR: "+1-787",PS: "+970",PT: "+351",PW: "+680",PY: "+595",RE: "+262",RO: "+40",RS: "+381",RU: "+7",RW: "+250",SB: "+677",SC: "+248",SD: "+249",SE: "+46",
          SG: "+65",SH: "+290",SI: "+386",SJ: "+47",SK: "+421",SL: "+232",SM: "+378",SN: "+221",SO: "+252",SR: "+597",SS: "+211",ST: "+239",SV: "+503",SX: "+1-721",SY: "+963",SZ: "+268",TC: "+1-649",TD: "+235",TF: "+262",TG: "+228",
          TH: "+66",TJ: "+992",TK: "+690",TL: "+670",TM: "+993",TN: "+216",TO: "+676",TR: "+90",TT: "+1-868",TV: "+688",TW: "+886",TZ: "+255",UA: "+380",UG: "+256",UM: "+1",US: "+1",UY: "+598",UZ: "+998",VA: "+39-06",VC: "+1-784",
          VE: "+58",VG: "+1-284",VI: "+1-340",VN: "+84",VU: "+678",WF: "+681",WS: "+685",XK: "+383",YT: "+262",ZA: "+27",ZM: "+260",ZW: "+263",
        },
        isChecked: false,
        orderUpdated: false,
        showModal: false,
        cartItems: [],
        cartSummary: {
          total_price: 0,
          total_price_discount: 0,
          family_or_friend_discount: 0,
          currency_ar: '',
          total_items_count: 0,
          coupon_code: null,
          discount: 0,
        },
        firstname: '',
        lastname: '',
        country: '',
        phone: '',
        email: '',
        couponCode: '',
        successMessage: '',
        errors: {},
        errorMessage: '',
        validationErrorMessage: '',
        link: false,
        selectedPaymentGateway: '',
        baseUrl: process.env.VUE_APP_BASE_URL,
      };
    },
    methods: {
      async fetchCartItems() {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/cart' : '/api/session/cart';
        let totalCartItems = 0;
        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        const textElement1 = document.getElementById('totalCount1');
        const textElement2 = document.getElementById('totalCount2');

        axios.get(this.baseUrl + url, { headers })
          .then(response => {
            totalCartItems = response.data.data.total_items_count;

            textElement1.textContent = totalCartItems > 0 ? totalCartItems : 0;
            textElement2.textContent = totalCartItems > 0 ? totalCartItems : 0;

            // Store cart items in data
            this.cartItems = response.data.data.items || [];
            this.orderUpdated = false; // Reset update status
            this.cartSummary = response.data.data;
            if(this.cartSummary.coupon_code){
              this.couponCode = this.cartSummary.coupon_code['code'];
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 200) {
              //   // If the cart is not found, treat it as empty
              console.log('Cart is empty or was deleted:', error.response.data);
            } else {
              console.log('Error fetching cart items:', error.response ? error.response.data : error.message);
            }
          });
      },
      async increaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/increase/${itemId}` : `/api/session/cart/increase/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(this.baseUrl + url, { headers })
          .then(() => {
            this.fetchCartItems();
            // window.location.reload();
          }) // Refresh cart items
          .catch(error => {
            console.error('Error increasing item quantity:', error);
          });
      },
      async decreaseQuantity(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/decrease/${itemId}` : `/api/session/cart/decrease/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(this.baseUrl + url, { headers })
          .then(() => {
            this.fetchCartItems();
            // window.location.reload();
          }) // Refresh cart items
          .catch(error => {
            console.error('Error decreasing item quantity:', error);
          });
      },
      async removeItem(itemId) {
        let url = sessionStorage.getItem('userInfo') ? `/api/user/cart/remove/${itemId}` : `/api/session/cart/remove/${itemId}`;

        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(this.baseUrl + url, { headers })
          .then(() => {
            this.fetchCartItems();
            // window.location.reload();
          })
          .catch((error) => {
            this.errorMessage = "Error removing item.";
            console.error("Error removing item:", error);
          });
      },
      async applyCoupon() {
        // Reset messages
        this.successMessage = "";
        this.errorMessage = "";

        if (!this.couponCode.trim()) {
          this.errorMessage = "Please enter a valid coupon code.";
          return;
        }
        try {
          let url = sessionStorage.getItem("userInfo")
            ? "/api/user/cart/apply-coupon"
            : "/api/session/cart/apply-coupon";

          const userInfo = sessionStorage.getItem("userInfo");
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers["Authorization"] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error("Error parsing userInfo from sessionStorage:", error);
            }
          }

          const response = await axios.post(
            this.baseUrl + url,
            { coupon_code: this.couponCode },
            { headers }
          );

          if (response.data.success) {
            this.cartSummary = response.data.data.cartSummary;
            this.successMessage = response.data.message;
            this.couponCode = this.cartSummary.coupon_code; // Clear the input field on success
          } else {
            this.errorMessage = response.data.message;
          }
        } catch (error) {
          this.errorMessage = error.response.data.data.error;
        }
      },
      async removeCoupon() {
        // Reset messages
        this.successMessage = "";
        this.errorMessage = "";

        try {
          let url = sessionStorage.getItem("userInfo")
            ? "/api/user/cart/remove-coupon"
            : "/api/session/cart/remove-coupon";

          const userInfo = sessionStorage.getItem("userInfo");
          let headers = {};
          if (userInfo) {
            try {
              const parsedUserInfo = JSON.parse(userInfo);
              const token = parsedUserInfo.token;

              if (token) {
                headers["Authorization"] = `Bearer ${token}`;
              }
            } catch (error) {
              console.error("Error parsing userInfo from sessionStorage:", error);
            }
          }

          const response = await axios.post(this.baseUrl + url, {}, { headers });
          console.log(response.data);
          if (response.data.success) {
            this.cartSummary = response.data.data.cartSummary || {};
            this.successMessage = response.data.message;
            this.couponCode = ""; // Clear the input field when coupon is removed
          } else {
            this.errorMessage = response.data.message;
          }
        } catch (error) {
          this.errorMessage = error.response.data.data.error;
        }
      },
      goToCourses() {
        this.$router.push("/ar/packages");
      },
      validateForm() {
        this.errors = {};

        if (!this.firstname) {
          this.errors.firstname = "الاسم الأول مطلوب";
        }

        if (!this.lastname) {
          this.errors.lastname = "الاسم الأخير مطلوب";
        }

        if (!this.country) {
          this.errors.country = "البلد / المنطقة مطلوب";
        }

        if (!this.phone) {
          this.errors.phone = "رقم الجوال مطلوب";
        }

        if (!this.email) {
          this.errors.email = "البريد الإلكتروني مطلوب";
        } else if (!this.isValidEmail(this.email)) {
          this.errors.email = "البريد الإلكتروني غير صالح";
        }
      },
      isValidEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      goToCheckout() {
        let url = sessionStorage.getItem("userInfo")
        ? '/api/user/checkout/'+this.selectedPaymentGateway
        : '/api/session/checkout/'+this.selectedPaymentGateway;

        const userInfo = sessionStorage.getItem("userInfo");
        let headers = {};
        let formData = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              headers["Authorization"] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error("Error parsing userInfo from sessionStorage:", error);
          }
        } else {
          formData = {
            firstname: this.firstname,
            lastname: this.lastname,
            country: this.country,
            phone: this.phone,
            email: this.email,
          };
          this.validateForm();
        }

        // If there are no errors, submit the form
        if (Object.keys(this.errors).length === 0) {
          axios.post(this.baseUrl + url, formData, { headers })
            .then((response) => {
              // Handle successful form submission
              console.log('Order checkout successfully:', response.data);
              console.log(response.data.success);
              if (response.data.success) {
                const responseData = response.data.data;
                if (!userInfo) {
                  // Save user information to sessionStorage
                  const user = responseData.user;
                  const token = responseData.token;
                  sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify({
                      id: user.id,
                      name: user.name,
                      email: user.email,
                      token: token,
                    })
                  );
                }
                // Redirect to the URL in the response data
                // window.location.href = responseData.stripeUrl;
                window.location.href = response.data.data.paymentUrl;
              } else {
                console.error("Error:", response.data.message);
                // Optionally, display an error message to the user
              }
            })
            .catch((error) => {
              // Handle errors
              console.log(error);
              console.error('Error submitting form:', error.response.data);
              this.validationErrorMessage = error.response.data.data.error || 'حدث خطأ أثناء إرسال النموذج. حاول مرة أخرى.';
              if((error.response?.data?.message).includes('User Exists')){
                this.link = true;
              }
            });
        }
      },
      showAuthModal(paymentGateway) {
        this.selectedPaymentGateway = paymentGateway;
        console.log(this.selectedPaymentGateway);
        if (sessionStorage.getItem("userInfo")) {
          this.goToCheckout();
        } else {
          this.showModal = true;
        }
      },
      closeModal() {
        this.showModal = false;
      },
      updatePhoneNumber() {
        // Get the selected country code and set it to the phone input
        const countryCode = this.country;

        // Set phone to the country code only if it exists
        if (this.countryCodes[countryCode]) {
          this.phone = this.countryCodes[countryCode]; // Set phone to country code
        } else {
          this.phone = ''; // Clear if no valid country is selected
        }
      },
      handleScroll(event) {
        const { scrollTop, scrollHeight, clientHeight } = event.target;

        const tolerance = 2; // Adjust tolerance as needed
        if (scrollTop + clientHeight >= scrollHeight - tolerance) {
          this.isChecked = true;
        } else {
          // If the user scrolls back up, uncheck the checkbox
          this.isChecked = false;
        }
      },
    },
    computed: {
      sortedCountries() {
        // Create an array of countries from the countries-list package
        const countryArray = Object.keys(countries).map((code) => ({
          code: code,
          name: countries[code].name,
        }));

        const preferred = this.preferredCountries
          .map((code) => countryArray.find((c) => c.code === code))
          .filter(Boolean);
        const others = countryArray.filter(
          (country) => !this.preferredCountries.includes(country.code)
        );
        return [...preferred, ...others]; // Combine preferred and other countries
      },
    },
    mounted() {
      const today = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      document.getElementById("currentDate").textContent = today.toLocaleDateString("ar-EG", options);

      const termsContent = this.$refs.termsContent;
      if (termsContent) {
        termsContent.addEventListener("scroll", this.handleScroll);
      }
    },
    beforeMount() {
      this.fetchCartItems();
    },
    unmounted() {
      const termsContent = this.$refs.termsContent;
      if (termsContent) {
        termsContent.removeEventListener("scroll", this.handleScroll);
      }
    },
  };
</script>

<style scoped>
  .shopping-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("https://monglish.b-cdn.net/home/cart_bg.png");
    background-size: cover;
    background-position: center;
    direction: rtl;
    margin-top: -85px;
  }

  .cart-main {
    display: flex;
    width: 100%;
  }

  .cart-info {
    text-align: right;
    opacity: 0.9;
    margin-bottom: 5%;
    margin-right: 5%;
  }

  .cart-info h2 {
    font-size: 3rem;
    color: #fff;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
    margin-top: 200px;
  }

  .cart-page {
    display: flex;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
    justify-content: center;
    width: 100%;
    background-color: white;
  }

  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    gap: 20px;
  }

  .empty-cart img {
    width: 20vw;
  }

  .empty-cart h2 {
    font-size: 2rem;
    color: #333;
    line-height: 80%;
  }

  .empty-cart p {
    font-size: 1.2rem;
    color: #666;
    line-height: 100%;
  }

  .back-to-courses {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
  }

  /* cart items */
  .cart-content {
    display: flex;
    width: 100%;
  }

  .cart-container {
    padding: 30px;
    border-radius: 15px;
    width: 100%;
    margin-bottom: 20px;
  }

  .cart-container-1 {
    max-width: 70%;
    position: relative;
    right: 0;
    top: -90px;
  }

  .cart-container-2 {
    max-width: 30%;
    position: relative;
    right: 0;
    top: -50px;
    background: white;
  }

  .coupon-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .coupon-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  #coupon-code {
    padding: 15px;
    border-radius: 15px;
    margin-left: 10px;
    border: 1px solid #ccc;
    width: 60%;
  }

  .remove-coupon-section {
    color: #ff1919;
  }

  .apply-button,
  .remove-coupon-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
    width: 40%;
  }

  .remove-coupon-button {
    background: linear-gradient(45deg, #fd7878, #fa5353);
  }

  .price-container {
    position: relative;
    border-radius: 20px;
    padding: 1px;
    margin-bottom: 20px;
  }

  .price-section {
    padding: 10px;
    border-radius: 20px;
  }

  .price-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    font-weight: bold;
    font-family: "DIN Next LT Arabic";
    font-size: 20px;
  }

  .price-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 18px;
  }

  .price-buy-button {
    display: flex;
    justify-content: center;
  }

  .confirm-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    border-radius: 15px;
    padding: 15px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .payment-type-container {
    display: flex;
    justify-content: space-between;
  }

  .payment-type-container .price-section {
    width: 15rem;
  }

  .payment-content {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .payment-content p {
    font-size: 15px;
  }

  .order-section {
    padding: 15px;
  }

  .order-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
  }

  .order-table-mobile {
    display: none;
  }

  .order-table th,
  .order-table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    border-left: 0;
    border-right: 0;
  }

  .order-table th {
    border-top: 0;
    padding-bottom: 20px;
    color: white;
  }

  .order-item {
    background-color: white;
  }

  .price {
    color: #f47920;
    font-size: 26px;
    font-weight: 600;
  }

  .quantity {
    align-items: center;
    justify-content: center;
  }

  .quantity input {
    width: 40px;
    text-align: center;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }

  .quantity button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #808b94;
    padding: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .course {
    font-size: 26px;
    font-weight: bold;
    transition: color 0.3s;
  }

  .course:hover {
    color: #f47920;
  }

  .remove-button {
    background-color: transparent;
    border: 1px solid #f47d21;
    border-radius: 50%;
    color: #f47920;
    cursor: pointer;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
  }

  .remove-button:hover {
    background-color: #f47920;
    color: white;
  }

  .buy-another-course {
    background-color: #165e84;
    color: #fff;
    padding: 16px 20px 16px 20px;
    margin: 0px 0px 0px 0px;
    font-family: "DIN Next LT Arabic-n", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
  }
  /* end cart item */

  .order-update-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }

  .success-message {
    color: green;
    margin-top: 1em;
  }

  .error-message {
    color: red;
    margin-top: 1em;
  }

  .validation-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .error {
    color: red;
  }

  .text-linkkk {
    color: #0f73a8 !important;
    text-decoration: underline !important;
  }

  .text-linkkk:hover {
    color: #034b72 !important;
    text-decoration: none !important;
  }

  .checkout-form-group {
    margin-bottom: 18px;
    text-align: right;
  }
  .checkout-form-group label {
    margin-bottom: 100px;
    margin-left: 10px;
    font-size: 18px;
    color: #333;
  }
  .checkout-form-group input,
  .checkout-form-group select {
    padding: 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 50%;
    text-align: right;
  }

  .checkout-button {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: #fff;
    padding: 10px;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .checkout-button:hover {
    background-color: #ff9442;
  }

  .checkout-button:disabled {
    background: linear-gradient(45deg, #686868, #cacaca);
    color: #666; /* Darker grey text */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  }

  .checkout-form {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .personal-section {
    width: 100%;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: transparent;
    margin: 0 auto;
    width: 65%;
  }

  .modal-header {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    padding: 1% 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
  }

  .modal-header h2 {
    font-size: 1.8rem;
    color: #fff;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
  }

  .modal-header p {
    font-size: 1.1rem;
    color: #fff;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
    justify-content: center;
  }

  .header-right {
    width: 40%;
    text-align: left;
    padding-right: 10px;
  }

  .header-left {
    width: 60%;
    text-align: right;
  }

  .modal-content form {
    margin-top: 50px;
  }

  .close {
    color: #fff;
    position: absolute; /* Position the SVG absolutely */
    top: 3%; /* Adjust as needed */
    right: 3%;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .checkout-div {
    margin-top: 2%;
    width: 100%;
    border-radius: 20px;
    background: #fff;
    padding: 1% 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-terms {
    margin-top: 2%;
    width: 100%;
    border-radius: 20px;
    background: #fff;
    padding: 1% 4%;
    display: flex;
    flex-direction: column;
  }

  .modal-terms h2 {
    color: #000;
    font-size: 1.4rem;
    font-family: "DIN Next LT Arabic";
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .terms-container {
    border-radius: 25px;
    height: 250px;
    overflow: hidden;
  }

  .terms-content {
    background-color: #eeeeee;
    border-radius: 25px;
    padding: 1.5rem;
    font-family: "DIN Next LT Arabic";
    color: #000;
    font-size: 1.1rem;
    margin-bottom: 3%;
    height: 250px;
    overflow-y: auto;
    border: 2px solid #ccc;
    position: relative;
  }

  .terms-content::after {
    content: "Scroll to the end to agree";
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9rem;
    color: #888;
  }

  .terms-content::-webkit-scrollbar {
    width: 4px;
  }

  .terms-content::-webkit-scrollbar-thumb {
    background-color: #ff6f00;
    border-radius: 10px;
  }

  .terms-content::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  .terms-content ol {
    margin: 0;
    padding-left: 1.5rem;
    list-style-type: decimal;
  }

  .terms-content a {
    font-weight: bold;
    color: #034b72;
  }

  .terms-content li {
    margin-bottom: 1rem;
    line-height: 1.7;
    margin-right: 1%;
  }

  .terms-content li ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }

  .terms-content li strong {
    font-weight: bold;
  }

  .checkout-formm {
    width: 100%;
    margin: 0 auto;
  }

  .validation-container {
    color: #721c24;
    padding: 10px;
    margin-bottom: 15px;
  }

  .checkout-formm .error {
    color: red;
  }

  .checkout-formm .text-link {
    color: #007bff;
    text-decoration: none;
  }

  .checkout-formm .input-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }

  .checkout-formm .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .checkout-formm .full-width {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .checkout-formm label {
    margin-bottom: 5px;
    color: #8d93a1;
  }

  .checkout-formm input,
  .checkout-formm select {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f7f9fc;
  }

  .checkout-formm input:focus,
  .checkout-formm select:focus {
    border-color: #f47920;
    outline: none;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    .cart-content {
      flex-direction: column;
    }
    .cart-container-1 {
      max-width: 100%;
      position: relative;
      right: 0;
      top: -40px;
      padding: 10px;
    }

    .order-table {
      display: none;
    }

    .order-table-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      font-family: "DIN Next LT Arabic";
      font-weight: 500;
    }

    .order-table-mobile tr {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      margin: 10px;
    }

    .remove-row {
      border-bottom: 0 !important;
      flex-direction: row-reverse;
    }

    .price-row td {
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .order-table-mobile td {
      text-align: center;
      padding: 10px;
      border-left: 0;
      border-right: 0;
    }

    .order-item {
      background-color: white;
    }

    .price {
      color: #f47920;
      font-size: 26px;
      font-weight: 600;
    }

    .quantity {
      align-items: center;
      justify-content: center;
    }

    .quantity input {
      width: 40px;
      text-align: center;
      margin: 0 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
    }

    .quantity button {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #808b94;
      padding: 5px;
      font-size: 18px;
      cursor: pointer;
    }

    .course {
      font-size: 26px;
      font-weight: bold;
      transition: color 0.3s;
    }

    .course:hover {
      color: #f47920;
    }

    .remove-button {
      background-color: transparent;
      border: 1px solid #f47d21;
      border-radius: 50%;
      color: #f47920;
      cursor: pointer;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s, color 0.3s;
    }

    .remove-button:hover {
      background-color: #f47920;
      color: white;
    }

    .cart-container-2 {
      max-width: 100%;
    }

    .price-table td {
      direction: rtl;
    }

    .modal-content {
      width: 90%;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 1.1rem;
  }

  .checkbox input {
    margin-left: 10px;
  }
</style>
