<template>
  <section class="home-reasons" style="direction: ltr;">
    <div class="content">
      <div class="t1">12 Reasons to Choose Monglish </div>
      <div class="t2">Learning English opens doors to career opportunities and effective international communication.</div>
      <div class="one-line gap-3">
        <MainButton @click="goToPackages">Pricing List</MainButton>
        <a href="/en/monglish-reasons/">
          <button class="info-btn one-line">
            More Information
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </a>
      </div>
    </div>
    
    <div class="carousel-container">
      <button @click="next" class="carousel-nav2 nexttttt">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
      <Carousel
          ref="carousel"
          :itemsToShow="itemsToShow"
          :wrapAround="true"
          :mouseDrag="true"
          :autoplay="4000"
          :transition="500"
          :gap="20"
      >
        <Slide v-for="(reason, index) in reasons" :key="index">
          <img :src="reason.img" :alt="`Reason ${index + 1} to choose Monglish`" class="reason-image" />
        </Slide>
      </Carousel>
      <button @click="prev" class="carousel-nav2 prevvvvv">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import MainButton from "@/components/Elements/MainButton.vue";

export default {
  components: {
    Carousel,
    Slide,
    MainButton
  },
  data() {
    return {
      reasons: [
        { img: 'https://monglish.b-cdn.net/home/reason-en/1.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/2.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/3.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/4.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/5.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/6.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/7.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/8.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/9.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/10.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/11.webp' },
        { img: 'https://monglish.b-cdn.net/home/reason-en/12.webp' },
      ]
    };
  },
  computed: {
    itemsToShow() {
      return window.innerWidth <= 700 ? 1 : 3;
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    goToPackages(){
      this.$router.push({ name: 'PackagesEn' });
    }
  }
};
</script>


<style src="@/assets/styles/ar/Reasons.css" scoped></style>
<style>
.carousel-nav2.nexttttt {
  right: 0;
}
</style>
