<template>
  <main class="section1">
    <div class="section1-content">
      <div class="text-content">
        <div class="welcome flex flex-col">
          <div>مرحبا بكم في الموقع الرسمي لأكاديمية مونجلش العالمية</div>
          <div class="desktop-view">MONGLISH GLOBAL ACADEMY (UK, USA, RUSSIA, CENTRAL ASIA, DUBAI, EGYPT)</div>
          <div class="mobile-view">
            MONGLISH GLOBAL ACADEMY <br>
            UK, USA, RUSSIA, CENTRAL ASIA, DUBAI, EGYPT
          </div>
        </div>
        <h1 class="title">تعلم الإنجليزية من خلال <br> الأكاديمية الأولى عالمياً </h1>
        <h2 class="subtitle">بمنهج المعايشة الفريد الخاص بنا</h2>
        
        <ul class="circle-list">
          <li v-for="(item, index) in features" :key="index">
            <i class="fa-solid fa-circle-check"></i> {{ item }}
          </li>
        </ul>
        <div class="one-line from-row-to-column">
          <MainButton @click="goToPackages">اشترك معنا الآن</MainButton>
          <a href="/ar/accredations">
            <button class="info-btn one-line">
              المزيد من المعلومات 
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </a>
        </div>
        <h2 class="title-secondary">اعتماداتنا و عضوياتنا</h2>
        <div class="accreditations-wrapper mt-2">
          <Carousel ref="carousel" :itemsToShow="8" :wrapAround="true" :mouseDrag="true" :autoplay="4000"
            :transition="500" :gap="20">
            <Slide v-for="(image, index) in accreditationImages" :key="index" class="carousel-slide">
              <img loading="lazy" :src="image.src" :alt="image.alt" class="reason-image" />
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="image-content hidden lg:block">
        <img loading="lazy" src="https://monglish.b-cdn.net/home/Desktop.png" />
      </div>
    </div>

    <div class="text-center mt-8">
      <h2 class="title-2">شُركاء النجاح</h2>
      <div class="companies">
        <img v-for="(company, index) in companyLogos" :key="index" :src="company.src" :alt="company.alt" />
      </div>
    </div>
  </main>
</template>

<script>
import MainButton from "../Elements/MainButton.vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "ViewOne",
  components: { Carousel, Slide, MainButton },
  data() {
    return {
      accreditationImages: this.loadImages('accreditations', 10),
      companyLogos: this.loadImages('companies2', 12),
      features: [
        "أندية دراسية يومياً",
        "معتمدون عالمياً",
        "تعلم أسرع 4 مرات من أي كورس آخر.",
      ],
    };
  },
  methods: {
    goToPackages() {
      this.$router.push({ name: "PackagesViewAr" });
    },
    loadImages(folder, count) {
      return Array.from({ length: count }, (_, index) => ({
        src: `https://monglish.b-cdn.net/${folder}/${index + 1}.webp`,
        alt: `Image ${index + 1} from ${folder} folder`,
      }));
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewOne.css" scoped></style>