<template>
  <div class="section">
    <div class="home-swiper-text">
      <h3>ننير مسار التعليم العالمي</h3>
      <h2>بأضواء المعرفة من كل ركن من أركان العالم</h2>
    </div>
    <div class="carousel-cont">
      <Carousel ref="carousel" :itemsToShow="itemsToShow" :wrapAround="true" :mouseDrag="true" :autoplay="4000"
        :transition="500">
        <Slide v-for="(location, index) in locations" :key="index">
          <div class="location-info">
            <img loading="lazy" :src="location.img" alt="Location Image" class="location-image" />
            <h2>{{ location.title }}</h2>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      locations: [
        {
          img: 'https://monglish.b-cdn.net/home/swiper/1.webp',
          title: 'مقر المملكة العربية السعودية - الرياض',
          location: 'Riyadh, الرياض'
        },
        {
          img: 'https://monglish.b-cdn.net/home/swiper/2.webp',
          title: 'مقر مصر - الاسكندرية',
          location: 'Alexandria, الإسكندرية'
        },
        {
          img: 'https://monglish.b-cdn.net/home/swiper/3.webp',
          title: 'مقر الولايات المتحدة الأمريكية - نيويورك',
          location: 'New York, نيويورك'
        },
        {
          img: 'https://monglish.b-cdn.net/home/swiper/4.webp',
          title: 'مقر المملكة المتحدة - لندن',
          location: 'London, لندن'
        }
      ]
    };
  },
  computed: {
    itemsToShow() {
      return window.innerWidth <= 700 ? 1 : 3;
    }
  },
};
</script>

<style scoped>
.section {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url('https://monglish.b-cdn.net/home/bg.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
}


.home-swiper-text {
  color: white;
  text-align: center;
  margin-block: 35px;
}

.home-swiper-text h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 70.56px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

.home-swiper-text h3 {
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

.carousel-cont {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-inline: 50px;
}

.location-image {
  width: 80%;
  margin-inline: 10%;
  object-fit: cover;
  border-radius: 10px;
}

.location-info {
  height: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: flex-start;
}

.location-info h2 {
  font-size: 1.5rem;
  margin-top: 5px;
}

.location-info p {
  font-size: 1rem;
}

@media (max-width: 1000px) {
  .home-swiper-text {
    margin-block: 20px;
  }

  .home-swiper-text h2 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  .home-swiper-text h3 {
    font-size: 1.5rem;
  }

  .carousel-cont {
    padding-bottom: 0;
  }

  .location-image {
    width: 90%;
    margin-inline: 0;
  }

  .location-info {
    height: 300px;
  }
}
</style>
